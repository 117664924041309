<template>
    <div style="position: relative;">
        <div class="w3-bar w3-theme-d1 de-btn w3-tiny" style="margin-bottom: 0px; padding: 3px;">
            <a @click="showMenu = !showMenu" title="Add"
                class="w3-bar-item w3-circle de-btn de-blue w3-green w3-small w3-hide-medium w3-hide-large "><i
                    class="fa fa-bars w3-medium"></i></a>

            <a title="Add" style="margin-right:3px;" v-if="statusAdd"
                class="w3-bar-item w3-btn de-btn de-blue w3-yellow w3-tiny w3-hide-small"
                v-on:click="buttonClick($event, showAdd);">
                <i class="fa fa-plus w3-medium"></i><span class="w3-hide-small"> Add</span>
            </a>
            <a title="Refresh" style="margin-right:3px;" v-if="statusRefresh"
                class="w3-bar-item w3-btn de-btn de-blue w3-green w3-tiny w3-hide-small"
                @click="buttonClick($event, showRefresh)"><i class="fa fa-refresh w3-medium"></i><span
                    class="w3-hide-small">
                    Refresh</span></a>
            <a title="Export" style="margin-right:3px;" v-if="statusExport"
                class="w3-bar-item w3-btn de-btn de-blue w3-deep-orange w3-tiny w3-hide-small" @click="generate"><i
                    class="fa fa-file-excel-o w3-medium"></i><span class="w3-hide-small"> Export</span></a>
            <a title="Print" style="margin-right:3px;" v-if="statusPrint"
                class="w3-bar-item w3-btn de-btn de-blue w3-tiny w3-hide-small"
                @click="buttonClick($event, showPrint)"><i class="fa fa-print w3-medium"></i><span
                    class="w3-hide-small"> Print</span></a>
            <slot name="button"></slot>
            <div v-if="statusFilter" class="w3-bar-item w3-btn de-btn de-blue w3-tiny"
                style="margin: 0 3px 0 0;padding: 0px;">
                <select v-on:change="setTypeQuery(filters.findQuery[0])" v-model="filters.findQuery[0]['field']"
                    class="w3-select w3-tiny" style="width:100px;height: 33px;">
                    <option value="">.: Cari :.</option>
                    <option v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">{{ fl.name }}
                    </option>
                </select>
            </div>
            <div v-if="statusFilter" class="w3-bar-item w3-btn de-btn de-blue w3-small"
                style="margin: 0 ;margin: 0 3px 0 0; padding:0px">


                <select v-on:change="prosesFilter" v-if="filters.findQuery[0].type == 'select'"
                    v-model="filters.findQuery[0].fieldValue" class="w3-select w3-small"
                    style="max-width:200px;display: inline-block!important; margin-right:0px;height: 33px;">
                    <option v-for="fl in filters.findQuery[0].valueFind" :key="fl.key" :value="fl.key">{{ fl.label }}
                    </option>
                </select>

                <div class="w3-text-black" v-else-if="['date', 'text', 'number'].includes(filters.findQuery[0].type)"
                    style="position: relative;max-width:200px;display: inline-block!important; margin-right:5px;">
                    <input v-on:click="filters.findQuery[0].showdate = true" @change="prosesFilter"
                        :type="filters.findQuery[0].type" class="w3-input w3-small" placeholder="Tanggal"
                        v-model="filters.findQuery[0].fieldValue" style="height: 33px;">

                </div>
                <input v-on:keyup.enter="prosesFilter" v-else type="text" class="w3-input w3-small"
                    v-model="filters.findQuery[0].fieldValue"
                    style="max-width:200px;display: inline-block!important; height: 33px;"
                    placeholder="Masukkan kata kunci ...">
            </div>
            <a title="Filter" style="margin-right:3px;" v-if="statusFilterAdvance"
                class="w3-bar-item w3-btn de-btn de-blue w3-tiny" @click="showFilterAdvance()"><i
                    class="fa fa-filter w3-medium"></i><span class="w3-hide-small"> Advance Filter</span></a>

            <div v-if="help != ''" class="w3-bar-item w3-right" style="margin: 0;padding: 2px 0 2px 0;">
                <button title="Bantuan untuk menu ini" v-on:click="showHelp()" type="button"
                    class="w3-btn w3-tiny de-btn de-blue " style="margin-right:2px;"><i class="fa fa-question"></i>
                </button>
            </div>

        </div>
        <div style="position: absolute; top: 25px; z-index: 1; padding-left: 1px;" v-if="showMenu"
            class="w3-hide-large w3-hide-medium w3-animate-zoom">
            <ul style="padding: 2px; width: 40px;">
                <li v-if="statusAdd" @click="() => { showAdd(); hideMenu(); }"
                    class="w3-bar-item w3-btn de-btn de-blue w3-blue w3-large" style="margin-top: 2px; width: 43px;"><i
                        class="fa fa-plus"></i></li>
                <li v-if="statusRefresh" @click="() => { showRefresh(); hideMenu(); }"
                    class="w3-bar-item w3-btn de-btn de-blue w3-green w3-large" style="margin-top: 2px; width: 43px;"><i
                        class="fa fa-refresh"></i></li>
                <li v-if="statusExport" @click="() => { generate(); hideMenu(); }"
                    class="w3-bar-item w3-btn de-btn de-blue w3-theme-d3 w3-large"
                    style="margin-top: 2px; width: 43px;"><i class="fa fa-file-excel-o"></i></li>
                <li v-if="statusPrint" @click="() => { showPrint(); hideMenu(); }"
                    class="w3-bar-item w3-btn de-btn de-blue w3-orange w3-large" style="margin-top: 2px; width: 43px;">
                    <i class="fa fa-print"></i>
                </li>
                <li v-if="statusFilterAdvance" @click="() => { showFilterAdvance(); hideMenu(); }"
                    class="w3-bar-item w3-btn de-btn de-blue w3-yellow w3-large" style="margin-top: 2px; width: 43px;">
                    <i class="fa fa-filter"></i>
                </li>
                <slot name="button-mobile"></slot>
            </ul>
        </div>

    </div>
    <DeModal name="Help" width="900px" icon="fa-question" title="Help" :showButton="false">
        <template #modal-body>
            <iframe v-if="help != ''" style="width: 100%;height: 600px;" :src="'https://www.youtube.com/embed/' + help"
                frameborder="0" title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </template>
    </DeModal>

    <!-- Untuk modal -->
    <DeModal name="Filter" width="600px" icon="fa-filter" title="Filter" :showButton="false">
        <template #modal-body>
            <div class="w3-theme-l1 w3-round" style="padding: 4px;">
                <div class="w3-row">
                    <div class="w3-col l12 m12 s12">
                        <select v-model="filters.operator" class="w3-select w3-small" style="width:100px;">
                            <option value="AND">AND</option>
                            <option value="OR">OR</option>
                        </select>
                        <button v-on:click="addFilter()" type="button" class="w3-small de-btn de-green"
                            style="margin-left:5px;height: 35px;"><i class="fa fa-plus"></i> Tambahkan
                            Kondisi</button>
                    </div>
                </div>
            </div>

            <div class="w3-theme-l1 w3-round" style="padding: 5px!important; margin-top: 5px;">
                <div class="w3-row" v-for="(item, index) in filters.findQuery" :key="index" style="margin-bottom: 2px;">
                    <div class="w3-col l12 m12 s12">
                        <select v-on:change="setTypeQuery(item)" v-model="item.field" class="w3-select w3-small"
                            style="width:150px;height: 34px;">
                            <option v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">
                                {{ fl.name }}</option>
                        </select>
                        <span>&nbsp;</span>
                        <select v-model="item.kondisi" class="w3-select w3-small"
                            style="width:50px;margin-right:2px!important;height: 34px;">
                            <option v-for="fl in filters.kondisi" :key="fl.key" :value="fl.key">{{ fl.key }}
                            </option>
                        </select>

                        <div v-if="item.type == 'date'"
                            style="position: relative;width:270px;display: inline-block!important; margin-right:2px;">
                            <input type="date" class="w3-input w3-small" placeholder="Tanggal"
                                v-model="item.fieldValue">
                        </div>
                        <div v-else-if="item.type == 'select'"
                            style="position: relative;width:270px;display: inline-block!important; margin-right:2px;">
                            <select v-if="item.type == 'select'" v-model="item.fieldValue" class="w3-select w3-small"
                                style="width:270px;display: inline-block!important; margin-right:2px;height: 34px;">
                                <option v-for="fl in item.valueFind" :key="fl.key" :value="fl.key">{{ fl.label }}
                                </option>
                            </select>
                        </div>
                        <div v-else
                            style="position: relative;width:270px;display: inline-block!important; margin-right:2px;">
                            <input type="text" class="w3-input w3-small" v-model="item.fieldValue"
                                style="width:270px;display: inline-block!important; margin-right:2px;">
                        </div>

                        <button v-on:click="removefilter(index)" type="button"
                            class="w3-btn w3-small w3-yellow de-btn de-red" style="margin-left:2px;height: 34px;"><i
                                class="fa fa-remove"></i></button>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="w3-right" style="margin-right: 10px; margin-bottom: 10px;">
                <button v-on:click="prosesFilter()" type="button" class="w3-btn de-btn de-blue w3-small"
                    style="margin-right:5px;"><i class="fa fa-search"></i> Filter</button>
                <button @click="closeFilter" class="w3-btn w3-red w3-small de-btn de-red"><i class="fa fa-close"></i>
                    Close</button>
            </div>
        </template>
    </DeModal>
</template>
<script>
import TableToExcel from "@linways/table-to-excel";
import { settingStore } from '@/stores/Setting';
import { ref } from "vue";
export default {
    name: "DeToolbar",
    mounted() {
        this.setCookie('export', 0, 1);
        setTimeout(() => {
            this.setCookie('export', 1, 1);
            // console.log(this.getCookie('export'));
            setTimeout(() => {
                this.setCookie('export', 0, 1);
                // console.log(this.getCookie('export'));
            }, 1000);
        }, 500);
    },
    components: {

    },
    setup() {
        const showMenu = ref(false);
        const filters = ref({
            operator: "AND",
            kondisi: [
                { key: "=" },
                { key: "like" },
                { key: ">" },
                { key: ">=" },
                { key: "<" },
                { key: "<=" },
                { key: "!=" }
            ],
            findQuery: [
                { field: "", kondisi: "like", fieldValue: "", type: "text", showdate: false }
            ]
        });
        const setting = settingStore();
        return { showMenu, filters, setting };
    },
    // data() {
    //     return {
    //         showMenu: false,
    //         filters: {
    //             operator: "AND",
    //             kondisi: [
    //                 { key: "=" },
    //                 { key: "like" },
    //                 { key: ">" },
    //                 { key: ">=" },
    //                 { key: "<" },
    //                 { key: "<=" },
    //                 { key: "!=" }
    //             ],
    //             findQuery: [
    //                 { field: "", kondisi: "like", fieldValue: "", type: "text", showdate: false }
    //             ]
    //         }
    //     };
    // },
    props: {
        "idtable": {
            type: String,
            default: "grid"
        },
        // findQuery: {
        //     type: Object,
        //     required: false,
        //     default: {}
        // },
        "fieldFind": {
            type: Array,
            required: true
        },
        setFilter: {
            type: Function,
            required: true,
            default: () => { }
        },
        clickAdd: {
            type: Function,
            default: () => { }
        },
        clickFilter: {
            type: Function,
            default: () => { }
        },
        clickRefresh: {
            type: Function,
            default: () => { }
        },
        clickExport: {
            type: Function,
            default: () => { }
        },
        clickPrint: {
            type: Function,
            default: () => { }
        },
        clickHideMenu: {
            type: Function,
            default: () => { }
        },
        /**Untuk export xls*/
        // mime type [xls, csv], default: xls
        "type": {
            type: String,
            default: "xls"
        },
        // Json to download
        "data": {
            type: Array,
            required: false
        },
        "fields": {
            type: Object,
            required: false
        },
        "exportFields": {
            type: Object,
            required: false
        },
        "title": {
            default: null
        },
        "footer": {
            default: null
        },
        "name": {
            type: String,
            default: "data.xls"
        },
        "meta": {
            type: Array,
            default: () => []
        },
        "help": {
            type: String,
            default: ""
        },
        statusAdd: {
            type: Boolean,
            default: true
        },
        statusRefresh: {
            type: Boolean,
            default: true
        },
        statusExport: {
            type: Boolean,
            default: true
        },
        statusPrint: {
            type: Boolean,
            default: true
        },
        statusFilter: {
            type: Boolean,
            default: true
        },
        statusFilterAdvance: {
            type: Boolean,
            default: false
        },
        "defaultFieldFind": {
            type: String,
            default: ""
        },
    },
    computed: {
        idName: function () {
            const now = new Date().getTime();
            return "export_" + now;
        },
        downloadFields: function () {
            if (this.fields != undefined)
                return this.fields;

            if (this.exportFields !== undefined)
                return this.exportFields;

            return "";
        }
    },
    methods: {
        hideMenu() {
            this.showMenu = !this.showMenu;
        },
        showHelp() {
            this.showModal("Help");
        },
        closeHelp() {
            this.closeModal("Help");
        },
        setTypeQuery(item) {
            let obj = this.findObj(this.fieldFind, "key", item.field);
            // console.log(obj,item.field,this.fieldFind);
            if (obj == undefined) {
                obj = this.findObj(this.fieldFind, "fieldFind", item.field);
            }
            item.type = obj.type;
            if (obj.type == "select") {
                item["valueFind"] = obj.valueFind;
            } else {
                item["valueFind"] = [];
                item["showdate"] = false;
                item["fieldValue"] = "";
            }
            // console.log(item, obj);
        },
        showFilterAdvance() {
            this.showModal("Filter");
        },
        closeFilter() {
            this.closeModal("Filter");
        },
        addFilter() {
            let obj = this.setFilterField(this.fieldFind);
            this.filters.findQuery.push({ field: obj[0]["kunci"], kondisi: "like", fieldValue: "", type: obj[0]["type"] });
            // this.setTypeQuery(this.filters.findQuery[0])
        },
        prosesFilter() {
            // console.log(this.filters.findQuery, this.filters.operator);
            /*for (let index = 0; index < this.filters.findQuery.length; index++) {
                const element = this.filters.findQuery[index];
                console.log('element', element["field"] != "");
                if (element["field"] == "") {
                    this.filters.findQuery[index]["kondisi"] = "";
                    this.filters.findQuery[index]["fieldvalue"] = "";
                } else {
                    if (element["kondisi"] == "" || element["kondisi"] == undefined) {
                        this.filters.findQuery[index]["kondisi"] = "like";
                    }
                }
            }*/
            if (this.filters.findQuery[0]['field'] == "") {
                // console.log(this.defaultFieldFind);
                if (this.defaultFieldFind != "") {
                    this.setFilter([{
                        "field": this.defaultFieldFind,
                        "fieldValue": this.filters.findQuery[0].fieldValue,
                        "kondisi": "like",
                        "order": this.defaultFieldFind
                    }], "AND");
                } else {
                    this.toast.error("Pilih field dulu");
                }
                return false;
            }
            this.setFilter(this.filters.findQuery, this.filters.operator);
        },
        removefilter(idx) {
            if (idx > 0) {
                this.filters.findQuery.splice(idx, 1);
                // this.$delete(this.filters.findQuery,idx);
            }
        },
        showAdd() {
            this.clickAdd();
        },
        showRefresh() {
            this.clickRefresh();
        },
        showPrint() {
            this.clickPrint();
        },
        setFilterField(fl) {
            let obj = [];
            fl.map(function (item) {
                if (item.filter == true) {
                    if (item["fieldFind"] != undefined) {
                        if (item["fieldFind"] != "") {
                            item["kunci"] = item["fieldFind"];
                        } else {
                            item["kunci"] = item["key"];
                        }
                    } else {
                        item["kunci"] = item["key"];
                    }
                    obj.push(item);
                }
            });
            return obj;
        },
        /**Untuk excel*/
        async generate() {
            const self = this;
            self.setting.setExport(true);
            // await self.removeCookie('export');
            self.setCookie('export', 1, 1);
            self.showLoading("Proses export");
            setTimeout(() => {
                self.hideLoading();
                TableToExcel.convert(document.getElementById(self.idtable), {
                    name: self.name,
                    sheet: {
                        name: self.name
                    }
                });
                setTimeout(() => {
                    // console.log('Proses restore');
                    self.setCookie('export', 0, 1);
                    self.setting.setExport(false);
                }, 3000);
            }, 2000);
        },

    },
};
</script>