<script setup>
import { defineModel, defineProps, toRefs, ref, computed, defineOptions } from "vue";
import DeModalHelp from "@/components/DeModalHelp.vue";
defineOptions({ inheritAttrs: false })
const model = defineModel();
const props = defineProps({
    TipeData: {
        "type": String,
        "default": "A"
    },
    Koma: {
        "type": Number,
        "default": 0
    },
    label: {
        "type": String,
        "default": ""
    },
    style: {
        "type": String,
        "default": ""
    },
    classDiv: {
        "type": String,
        "default": ""
    },
    Class: {
        "type": String,
        "default": ""
    },
    doBlur: {
        type: Function,
        required: false,
        default: () => { }
    },
    Id: {
        "type": String,
        "default": "txt1"
    },
    type: {
        "type": String,
        "default": "text"
    },
    readOnly: {
        "type": Boolean,
        "default": false,
    },
    maxlength: {
        "type": Number,
        "default": 255
    },
    minlength: {
        "type": Number,
        "default": 0
    },
    rows: {
        "type": Number,
        "default": 2
    },
    required: {
        "type": Boolean,
        "default": false
    },
    placeholder: {
        "type": String,
        "default": ""
    },
    keterangan: {
        "type": String,
        "default": ""
    },
});
const prop = toRefs(props);
// console.log('Koma', prop.blur);
const configMoney = computed(() => {
    return {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: prop.Koma.value,
        masked: false
    };
});

let showPass = ref(false);
function onBlur() {
    // console.log("Event", prop ,typeof prop.doBlur);
    // const blur = toRefs(props);
    if (prop.doBlur !== undefined && ['function'].includes(typeof prop.doBlur)) {
        prop.doBlur();
    } else if (['object'].includes(typeof prop.doBlur)) {
        prop.doBlur.value();
    }
}
</script>

<template>
    <DeModalHelp :name="'modalHelpForm' + Id" :title="' Keterangan form ' + placeholder">
        <template #modal-body>
            <div style="text-align: center;" v-html="keterangan"></div>
        </template>
    </DeModalHelp>
    <fieldset class="w3-round-large w3-card-2" v-bind:class="classDiv == '' ? 'w3-border w3-round-large' :
        classDiv" style="padding: 1px; position: relative;min-height: 55px;" v-bind:style="style">
        <legend class="w3-text-black " style="padding: 3px 5px 3px 5px; font-weight: bold;">{{ label }}</legend>
        <div :title="'Manual penggunaan ' + placeholder" v-if="keterangan != ''"
            @click="showModal('modalHelpForm' + Id)" type="button"
            style="position: absolute; right: 1px;top: -22px; height: 20px; width: 20px; padding: 0 0 2px 4px; cursor: pointer;"
            class="w3-border w3-round w3-theme-l2">
            <i class="fas fa-question w3-text-red"></i>
        </div>
        <input v-if="type === 'password'" :id="Id" v-on:change="onBlur" :style="style"
            v-bind:class="Class !== '' ? Class : 'w3-small'" :type="showPass ? 'text' : 'password'" v-model="model"
            class="w3-border-0 w3-input w3-round-large" :maxlength="maxlength" :minlength="minlength"
            :required="required" :placeholder="placeholder" :readonly="readOnly"
            style="outline: none; padding-top: 0; background: inherit;min-height: 26px;" />
        <input v-else-if="['text', 'date', 'email', 'number'].includes(type)" :id="Id" @blur="onBlur" :style="style"
            v-bind:class="Class !== '' ? Class : 'w3-small'" :type="type" v-model="model"
            class="w3-border-0 w3-input w3-round-large" :maxlength="maxlength" :minlength="minlength"
            :required="required" :placeholder="placeholder" :readonly="readOnly"
            style="outline: none; padding-top: 0; background: inherit;min-height: 26px;" />
        <money3 v-else-if="['money'].includes(type)" :id="Id" @blur="onBlur" :style="style"
            v-bind:class="Class !== '' ? Class : 'w3-small'" :type="type" v-model="model"
            class="w3-border-0 w3-input w3-round-large w3-right-align" :maxlength="maxlength" :minlength="minlength"
            :required="required" :placeholder="placeholder" :readonly="readOnly" v-bind="configMoney"
            style="outline: none; padding-top: 0; background: inherit;min-height: 26px;" />
        <textarea v-else-if="type === 'textarea'" :id="Id" @blur="onBlur" :style="style"
            v-bind:class="Class !== '' ? Class : 'w3-small'" v-model="model" class="w3-border-0 w3-input w3-round-large"
            :maxlength="maxlength" :minlength="minlength" v-bind:required="required" :placeholder="placeholder"
            :readonly="readOnly" :rows="rows"
            style="outline: none; padding-top: 0; background: inherit;min-height: 26px;">
    </textarea>
        <input v-else type="text" :required="required" v-model="model" class="w3-border-0 w3-input w3-round-large"
            style="position: absolute; z-index: -1;" />
        <slot name="komponen"></slot>
        <button @click="() => {
            showPass = !showPass;
        }" v-if="type === 'password'" type="button" class="w3-text-black w3-border-0 w3-small"
            style="position: absolute; right: 0px; bottom: 0px; background: inherit; padding: 8px; cursor: pointer;">
            <i class="fa" :class="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
    </fieldset>
</template>
<style>
fieldset:focus-within {
    border: 1px solid #545050 !important;
}

fieldset:has(> input:invalid) {
    border: 2px solid #d54747 !important;
}

fieldset:has(> textarea:invalid) {
    border: 2px solid #d54747 !important;
}

fieldset:has(> select:invalid) {
    border: 2px solid #d54747 !important;
}

/* Untuk multi select */
.multiselect {
    min-height: 27px;
}

.multiselect__select {
    height: 21px;
}

.multiselect__single {
    padding-left: 5px;
    margin-bottom: 2px;
}

.multiselect__tags {
    padding: 0px 40px 0px 8px;
    min-height: 27px;
}
</style>