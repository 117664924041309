/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router'
// import Config from '@/conf/Config.js'

const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        component: () => import("@/views/public/NoPage.vue"),
        name: "404",
        meta: {
            auth: false,
            title: "Not Found",
            icon: "fa fa-magic",
            closable: true
        }
    },
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/public/Main.vue"),
        meta: {
            auth: false,
            title: 'Dashboard'
        },
        children: [

            {
                path: "/",
                name: "HomeData",
                component: () => import("@/views/public/Home.vue"),
                meta: {
                    auth: false,
                    title: 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-home',
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/regulasi",
                name: "Regulasi",
                component: () => import("@/views/public/Regulasi.vue"),
                meta: {
                    auth: false,
                    title: 'Regulasi',
                    tips: 'Regulasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/asosiasi",
                name: "Asosiasi",
                component: () => import("@/views/public/Asosiasi.vue"),
                meta: {
                    auth: false,
                    title: 'Asosiasi',
                    tips: 'Asosiasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/bujk/:id/:name",
                name: "Bujk",
                component: () => import("@/views/public/Bujk.vue"),
                meta: {
                    auth: false,
                    title: 'Bujk',
                    tips: 'Bujk',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/potensi",
                name: "Potensi",
                component: () => import("@/views/public/Potensi.vue"),
                meta: {
                    auth: false,
                    title: 'Potensi',
                    tips: 'Potensi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/paketpekerjaan",
                name: "PaketPekerjaan",
                component: () => import("@/views/public/PaketPekerjaan.vue"),
                meta: {
                    auth: false,
                    title: 'PaketPekerjaan',
                    tips: 'PaketPekerjaan',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/tenagaahli",
                name: "TenagaAhli",
                component: () => import("@/views/public/TenagaAhli.vue"),
                meta: {
                    auth: false,
                    title: 'TenagaAhli',
                    tips: 'TenagaAhli',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/peserta/:id",
                name: "Peserta",
                component: () => import("@/views/public/Peserta.vue"),
                meta: {
                    auth: false,
                    title: 'Peserta',
                    tips: 'Peserta',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/tenagaterampil",
                name: "Tenagaterampil",
                component: () => import("@/views/public/Tenagaterampil.vue"),
                meta: {
                    auth: false,
                    title: 'Tenagaterampil',
                    tips: 'Tenagaterampil',
                    icon: 'fa fa-users',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/kecelakaan",
                name: "Kecelakaan",
                component: () => import("@/views/public/Kecelakaan.vue"),
                meta: {
                    auth: false,
                    title: 'Kecelakaan',
                    tips: 'Kecelakaan',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/daftarhitam",
                name: "DaftarHitam",
                component: () => import("@/views/public/DaftarHitam.vue"),
                meta: {
                    auth: false,
                    title: 'DaftarHitam',
                    tips: 'DaftarHitam',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/publikasi",
                name: "Publikasi",
                component: () => import("@/views/public/Publikasi.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/tugasdanfungsi",
                name: "TugasdanFungsi",
                component: () => import("@/views/public/TugasdanFungsi.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/strukturorganisasi",
                name: "StrukturOrganisasi",
                component: () => import("@/views/public/StrukturOrganisasi.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/programkegiatan",
                name: "ProgramKegiatan",
                component: () => import("@/views/public/ProgramKegiatan.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                // path: "/publikasidetail",
                path: "/publikasidetail/:id",
                name: "PublikasiDetail",
                component: () => import("@/views/public/PublikasiDetail.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/pelatihantenagakonstruksi",
                name: "PelatihanTenaganKonstruksi",
                component: () => import("@/views/public/Pelatihan.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/listujikompetensi",
                name: "listujikompetensi",
                component: () => import("@/views/public/UjiKompetensi.vue"),
                meta: {
                    auth: false,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },


        ]
    },
    // Untuk Rekanan
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/public/Login.vue"),
        meta: {
            auth: false,
            title: 'Login'
        },
    },
    // Login OPD
    {
        path: "/adminopd",
        name: "LoginOpd",
        component: () => import("@/views/public/LoginOpd.vue"),
        meta: {
            auth: false,
            title: 'Login'
        },
    },
    // Untuk admin
    {
        path: "/admin",
        name: "AdminLogin",
        component: () => import("@/views/public/AdminLogin.vue"),
        meta: {
            auth: false,
            title: 'AdminLogin'
        },
    },
    {
        path: "/main",
        name: "Main",
        component: () => import('@/views/admin/Main.vue'),
        meta: {
            auth: true,
            title: 'Dashboard',
            tips: 'Dashboard',
            icon: 'fa fa-home',
            closable: false,
            tabClass: 'w3-theme-l1',
        },
        children: [
            {
                path: '*',
                component: () => import("@/views/public/NoPage.vue"),
                name: "NoPageAnak",
                meta: {
                    auth: false,
                    title: "Not Found",
                    icon: "fa fa-magic",
                    closable: true
                }
            },
            {
                path: "/dashboard",
                name: "Dashboard",
                component: () => import("@/views/admin/Dashboard.vue"),
                meta: {
                    auth: true,
                    title: 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-dashboard',
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/DashboardRekanan",
                name: "DashboardRekanan",
                component: () => import("@/views/public/admin/Dashboard.vue"),
                meta: {
                    auth: false,
                    title: 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-dashboard',
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/DashboardOpd",
                name: "DashboardOpd",
                component: () => import("@/views/admin/opd/Dashboard.vue"),
                meta: {
                    auth: false,
                    title: 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-dashboard',
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/DashboardAsosiasi",
                name: "DashboardAsosiasi",
                component: () => import("@/views/public/asosiasi/Dashboard.vue"),
                meta: {
                    auth: false,
                    title: 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-dashboard',
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            // master
            {
                path: "/mkategori",
                name: "MKategori",
                component: () => import("@/views/admin/Kategori.vue"),
                meta: {
                    auth: true,
                    title: 'Kategori',
                    tips: 'Kategori',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Mopd",
                name: "MOpd",
                component: () => import("@/views/admin/Opd.vue"),
                meta: {
                    auth: true,
                    title: 'OPD',
                    tips: 'OPD',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mregulasi",
                name: "MRegulasi",
                component: () => import("@/views/admin/Regulasi.vue"),
                meta: {
                    auth: true,
                    title: 'Regulasi',
                    tips: 'Regulasi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/masosiasi",
                name: "MAsosiasi",
                component: () => import("@/views/admin/Asosiasi.vue"),
                meta: {
                    auth: true,
                    title: 'Asosiasi',
                    tips: 'Asosiasi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mbujk",
                name: "MBujk",
                component: () => import("@/views/admin/Bujk.vue"),
                meta: {
                    auth: true,
                    title: 'BUJK',
                    tips: 'BUJK',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mberita",
                name: "MBerita",
                component: () => import("@/views/admin/Bujk.vue"),
                meta: {
                    auth: true,
                    title: 'Berita',
                    tips: 'Berita',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mpotensi",
                name: "MPotensi",
                component: () => import("@/views/admin/Potensi.vue"),
                meta: {
                    auth: true,
                    title: 'Potensi',
                    tips: 'Potensi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mdaftarhitam",
                name: "MDaftarhitam",
                component: () => import("@/views/admin/DaftarHitam.vue"),
                meta: {
                    auth: true,
                    title: 'Daftarhitam',
                    tips: 'Daftarhitam',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mpaket",
                name: "MPaket",
                component: () => import("@/views/admin/PaketPekerjaan.vue"),
                meta: {
                    auth: true,
                    title: 'Paket Pekerjaan',
                    tips: 'Paket Pekerjaan',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mtenagaahli",
                name: "MTenagaAhli",
                component: () => import("@/views/admin/TenagaAhli.vue"),
                meta: {
                    auth: true,
                    title: 'Tenaga Ahli',
                    tips: 'Tenaga Ahli',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/masosiasi",
                name: "MAsosiasi",
                component: () => import("@/views/admin/Asosiasi.vue"),
                meta: {
                    auth: true,
                    title: 'Asosiasi',
                    tips: 'Asosiasi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mkecelakaan",
                name: "MKecelakaan",
                component: () => import("@/views/admin/Kecelakaan.vue"),
                meta: {
                    auth: true,
                    title: 'Kecelakaan Konstruksi',
                    tips: 'Kecelakaan Konstruksi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mpublikasi",
                name: "MPublikasi",
                component: () => import("@/views/admin/Publikasi.vue"),
                meta: {
                    auth: true,
                    title: 'Publikasi',
                    tips: 'Publikasi',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/mbidang",
                name: "MBidang",
                component: () => import("@/views/admin/Bidang.vue"),
                meta: {
                    auth: true,
                    title: 'Bidang',
                    tips: 'Bidang',
                    icon: 'fa fa-book',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Pengawasan
            {
                path: "/tertibusaha",
                name: "TertibUsaha",
                component: () => import("@/views/admin/pengawasan/TertibUsaha.vue"),
                meta: {
                    auth: false,
                    title: 'Tertib Usaha',
                    tips: 'Tertib Usaha',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/tertibpenyelenggaraan",
                name: "TertibPenyelenggaraan",
                component: () => import("@/views/admin/pengawasan/TertibPenyelenggaraan.vue"),
                meta: {
                    auth: false,
                    title: 'Tertib Penyelenggaraan',
                    tips: 'Tertib Penyelenggaraan',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/tertibpemanfaatan",
                name: "TertibPemanfaatan",
                component: () => import("@/views/admin/pengawasan/TertibPemanfaatan.vue"),
                meta: {
                    auth: false,
                    title: 'Tertib Pemanfaatan',
                    tips: 'Tertib Pemanfaatan',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Untuk Rekanan
            {
                path: "/rekananprofile",
                name: "rekananprofile",
                component: () => import("@/views/public/admin/Profile.vue"),
                meta: {
                    auth: true,
                    title: 'Profile',
                    tips: 'Profile',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/rekanantenagaahli",
                name: "rekanantenagaahli",
                component: () => import("@/views/public/admin/TenagaAhli.vue"),
                meta: {
                    auth: true,
                    title: 'Tenaga Ahli',
                    tips: 'Tenaga Ahli',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/rekananpekerjaan",
                name: "rekananpekerjaan",
                component: () => import("@/views/public/admin/Pekerjaan.vue"),
                meta: {
                    auth: true,
                    title: 'Paket',
                    tips: 'Paket',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/admin/Profile.vue"),
                meta: {
                    auth: true,
                    title: 'Profile',
                    tips: 'Profile',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/kelompokuser",
                name: "kelompokuser",
                component: () => import("@/views/admin/KelompokUser.vue"),
                meta: {
                    auth: true,
                    title: 'Group menu',
                    tips: 'Group menu',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Untuk OPD
            {
                path: "/opdprofile",
                name: "opdprofile",
                component: () => import("@/views/admin/opd/Profile.vue"),
                meta: {
                    auth: true,
                    title: 'Profile',
                    tips: 'Profile',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/opdpekerjaan",
                name: "opdpekerjaan",
                component: () => import("@/views/admin/opd/PaketPekerjaan.vue"),
                meta: {
                    auth: false,
                    title: 'PaketPekerjaan',
                    tips: 'PaketPekerjaan',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Untuk asosiasi
            {
                path: "/AnggotaAsosiasi",
                name: "AnggotaAsosiasi",
                component: () => import("@/views/public/asosiasi/Bujk.vue"),
                meta: {
                    auth: false,
                    title: 'Anggota',
                    tips: 'Anggota',
                    icon: 'fa fa-gears',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/asosiasiprofile",
                name: "asosiasiprofile",
                component: () => import("@/views/public/asosiasi/Profile.vue"),
                meta: {
                    auth: true,
                    title: 'Profile',
                    tips: 'Profile',
                    icon: 'fa fa-user',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Untuk Laporan
            {
                path: "/lapbujk",
                name: "lapbujk",
                component: () => import("@/views/admin/reports/LapBujk.vue"),
                meta: {
                    auth: true,
                    title: 'Lap. BUJK',
                    tips: 'Lap. BUJK',
                    icon: 'fa fa-area-chart',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/lappotensi",
                name: "lappotensi",
                component: () => import("@/views/admin/reports/LapPotensi.vue"),
                meta: {
                    auth: true,
                    title: 'Lap. Potensi',
                    tips: 'Lap. Potensi',
                    icon: 'fa fa-area-chart',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/lappaketpekerjaan",
                name: "lappaketpekerjaan",
                component: () => import("@/views/admin/reports/LapPaketPekerjaan.vue"),
                meta: {
                    auth: true,
                    title: 'Lap. Paket Pekerjaan',
                    tips: 'Lap. Paket Pekerjaan',
                    icon: 'fa fa-area-chart',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/laptenagaterampil",
                name: "laptenagaterampil",
                component: () => import("@/views/admin/reports/LapTenagaTerampil.vue"),
                meta: {
                    auth: true,
                    title: 'Lap. Tenaga Terampil',
                    tips: 'Lap. Tenaga Terampil',
                    icon: 'fa fa-area-chart',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },

            // Manage user
            {
                path: "/manageuser",
                name: "manageuser",
                component: () => import("@/views/admin/ManageUser.vue"),
                meta: {
                    auth: true,
                    title: 'Manage User',
                    tips: 'Manage User',
                    icon: 'fa fa-users',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },

            // Untuk update program
            {
                path: "/opdorganisasi",
                name: "OpdOrganisasi",
                component: () => import("@/views/admin/opd/OpdOrganisasi.vue"),
                meta: {
                    auth: false,
                    title: 'Struktur Organisasi',
                    tips: 'Struktur Organisasi',
                    icon: 'fa fa-home',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/opdkegiatan",
                name: "OpdKegiatan",
                component: () => import("@/views/admin/opd/OpdKegiatan.vue"),
                meta: {
                    auth: false,
                    title: 'Program Kegiatan',
                    tips: 'Program Kegiatan',
                    icon: 'fa fa-home',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/opdtugas",
                name: "OpdTugas",
                component: () => import("@/views/admin/opd/OpdTugas.vue"),
                meta: {
                    auth: false,
                    title: 'Program Tugas',
                    tips: 'Program Tugas',
                    icon: 'fa fa-home',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Pelatihan
            {
                path: "/pelatihankerja",
                name: "PelatihanKerja",
                component: () => import("@/views/admin/Pelatihan/PelatihanKerja.vue"),
                meta: {
                    auth: false,
                    title: 'PelatihanKerja',
                    tips: 'PelatihanKerja',
                    icon: 'fa fa-home',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/ujikompetensi",
                name: "UjiKompetensi",
                component: () => import("@/views/admin/Pelatihan/UjiKompetensi.vue"),
                meta: {
                    auth: false,
                    title: 'UjiKompetensi',
                    tips: 'UjiKompetensi',
                    icon: 'fa fa-home',
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    try {
        // console.log(to);
        if (to.name == null) {
            next("/home");
        }
        if (to.matched.some(record => record.meta.auth)) {
            vm.checkLogin(function (res) {
                if (res.success == true) {
                    next();
                } else {
                    next("/");
                }
            }, function (err) {
                next("/");
            });
        } else {
            next();

        }
    } catch (error) {
        next("/home");
        // console.log('router error1', error);
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        // NProgress.start();
        // this.showLoading("Loading ..");
        document.getElementById('txt-loading').innerHTML = "Loading ..";
        document.getElementById('idLoading').style.display = 'block';
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    // NProgress.done()
    document.getElementById('idLoading').style.display = 'none';
});

export default router
