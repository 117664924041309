// import './registerServiceWorker'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import mixin from './mixin.js';

// Untuk Form
// import {ModelListSelect} from '@/components/vue-select';
import money from 'v-money3'
// import VaSelect from 'va-select'
import SwitchButton from "@/components/switch-button/SwitchButton.vue";
import Paging from "@/components/Paging.vue";
import Toolbar from "@/components/Toolbar.vue";
import DeLoading from "@/components/DeLoading.vue";
import DeModal from "@/components/DeModal.vue";
import VueScrollingTable from '@/components/tablescroll/VueScrollingTable.vue';
require("@/assets/css/style-admin.css");

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueMultiselect from '@/components/vue-multiselect'

/* eslint-disable */
/** sweet alert */
import swal from "@/assets/swal/sweet-alert.min.js";
require("@/assets/swal/sweet-alert.css");
require("./assets/css/report.css");
require("@/assets/swal/theme.css");
import moment from 'moment';
import FormField from "@/components/FormField";

const app = createApp(App);
app.mixin(mixin);
// app.component("VaSelect", VaSelect);
app.component("VueMultiselect", VueMultiselect);
app.component("SwitchButton", SwitchButton);
app.component("Paging", Paging);
app.component("Toolbar", Toolbar);
app.component("DeLoading", DeLoading);
app.component("VueScrollingTable", VueScrollingTable);
app.component("DeModal", DeModal);
app.component("FormField", FormField);
// app.config.productionTip = false;

// const options = {
//     position: "top",
//     duration: 3000
// };

app.use(money);
app.use(Toast, {
    position: "top-center",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
});

app.use(router);
app.use(createPinia());

let vm = app.mount('#app');
global.vm = vm;
// console.log(vm);
